export default {
  id: 'bf1802fff3',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page background-image background-mountain" data-name=feedbackform><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class="link back"><i class="icon material-icons">navigate_before</i></a></div></div></div><!-- Toolbar--><!-- Scrollable page content--><div id=feedbackform-page-content class=page-content><div class=page-content-top><h1 id=feedbackform-title class=page-title>Feedback</h1><h2 class=page-subtitle></h2></div><div class=page-content-main><div><p>Wir freuen uns wenn du dir einen Moment nimmst, um uns ein kurzes Feedback zu der App zu geben.</p><p>Wie würdest du die App gesamt bewerten?</p></div><div id=feedbackform></div></div></div><div id=feedbackform-popover-menu class=popover><div class=popover-inner><div class=list><ul><li><a href=https://www.iubenda.com/privacy-policy/25035371 class="iubenda-white no-brand iubenda-embed iub-body-embed link external" target=_blank title="Privacy Policy">Datenschutzerklärung</a><script type=text/javascript>(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script></li><li><a class="list-button item-link" href=/impressum/ >Impressum</a></li></ul></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};