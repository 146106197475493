export default {
  id: 'efee984d0a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=idea><!-- Navbar --><div class=navbar></div><!-- Toolbar --><div class=main-toolbar></div><!-- Scrollable page content--><div id=idea-page-content class=page-content><div class=page-content-top><h1 id=idea-title class=page-title>Meine Idee</h1><div id=idea-tabbar class="heading-toolbar toolbar tabbar no-shadow"><div class=toolbar-inner><a href=#idea-tab-job class="tab-link tab-link-active" data-animate=false>Ausbildung</a> <a href=#idea-tab-studies class=tab-link data-animate=false>Studium</a></div></div><div id=idea-tabs class="heading-toolbar-tabs tabs"><div id=idea-tab-job class="tab tab-active"><!-- empty --></div><div id=idea-tab-studies class=tab><!-- empty --></div></div></div><div class=page-content-main><div class=list><div class=list-group><ul><li><div class=item-content><div class=item-inner><input id=idea-name class="idea-input bold-placeholder" type=text placeholder=""></div></div></li><li><div class=item-content><div class=item-inner><input id=idea-notes class=idea-input type=text placeholder="Rechercheergebnisse und Notizen zu dieser Idee"></div></div></li></ul></div></div><div class="idea-oracle-button button-list shadowed"><a id=idea-search href=# target=_system class="link external button button-fill color-white full-width">Nach Idee suchen</a></div><div id=idea-list-options></div><div id=idea-list-pro></div><div id=idea-list-contra></div></div></div><div id=idea-popover-menu class=popover><div class=popover-inner><div class=list><ul><li><a id=idea-popover-delete class="list-button item-link" href=#>Idee löschen</a></li><li><a id=idea-popover-export class="list-button item-link" href=#>Idee exportieren</a></li><li><a id=idea-popover-edit class="list-button item-link" href=#>Idee bearbeiten</a></li></ul></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};