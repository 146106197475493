export default {
  id: 'db1bf9799f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page background-mountain background-image" data-name=advise><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class="link back"><i class="icon material-icons">navigate_before</i></a></div></div></div><!-- Toolbar --><div class=main-toolbar></div><!-- Scrollable page content--><div class="page-content advise-content"></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};