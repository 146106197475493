export default {
  id: 'fdb693e714',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page background-image background-mountain" data-name=expertise-edit><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class=link><i class="icon material-icons">navigate_before</i></a></div></div></div><!-- Toolbar--><div class=main-toolbar></div><!-- Floating Action Button --><div class=main-fab></div><!-- Scrollable page content--><div class=page-content><div class=page-content-top><h1 id=expertise-edit-title class=page-title></h1></div><div class=page-content-main><div id=expertise-edit-text></div><div id=expertise-edit-entries></div></div><div class=page-content-bottom><div class="button-list shadowed horizontal"><button id=expertise-edit-button-left class="button button-fill color-white full-width"></button> <button id=expertise-edit-button-right class="button button-fill color-white full-width"></button></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};