export default {
  id: 'fcb1b113ec',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page background-image background-mountain" data-name=authchange><!-- Navbar --><div class="navbar navbar-transparent no-shadow no-hairline"><div class=navbar-inner><div class=left><a class="link back"><i class="icon material-icons">navigate_before</i></a></div><div class=right><a class="link popover-open" href=# data-popover=#home-popover-menu><i class="icon material-icons">more_vert</i></a></div></div></div><!-- Scrollable page content--><div id=authchange-page-content class=page-content><div class=page-content-top><h1 class=page-title>Hey du!</h1><p class=font-text id=authchange-disclaimer data-dic-key=authchangetext></p></div><div class=page-content-main><div class=row><div class=col><div class="block no-padding no-margin"><div id=email-input></div><div id=password-input></div><div id=passwordconfirm-input></div></div></div></div></div><div class=page-content-bottom><a href=# id=authchange-button class="button button button-large button-raised button-fill color-white text-color-black" data-dic-key=saveauth></a></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};